@font-face {
  font-family: "UhbeeSehyun";
  font-weight: 400;
  src: url('../fonts/UhBeeSe_hyun.eot');
  src: local('UhBee Se_hyun'),
       url('../fonts/UhBeeSe_hyun.eot?#iefix') format('embedded-opentype'),
       url('../fonts/UhBeeSe_hyun.woff') format('woff'),
       url('../fonts/UhBee\ Se_hyun.ttf') format('truetype');
}

@font-face {
 font-family: 'NanumSquare';
 font-weight: 400;
 src: url('../fonts/NanumSquareR.eot');
 src: local('나눔스퀘어'),
      url('../fonts/NanumSquareR.eot?#iefix') format('embedded-opentype'),
      url('../fonts/NanumSquareR.woff2') format('woff2'),
      url('../fonts/NanumSquareR.woff') format('woff'),
      url('../fonts/NanumSquareR.ttf') format('truetype');
}
@font-face {
 font-family: 'NanumSquare';
 font-weight: 700;
 src: url('../fonts/NanumSquareB.eot');
 src: local('나눔스퀘어 Bold'),
      url('../fonts/NanumSquareB.eot?#iefix') format('embedded-opentype'),
      url('../fonts/NanumSquareB.woff2') format('woff2'),
      url('../fonts/NanumSquareB.woff') format('woff'),
      url('../fonts/NanumSquareB.ttf') format('truetype');
}
@font-face {
 font-family: 'NanumSquare';
 font-weight: 800;
 src: url('../fonts/NanumSquareEB.eot');
 src: local('나눔스퀘어 ExtraBold'),
      url('../fonts/NanumSquareEB.eot?#iefix') format('embedded-opentype'),
      url('../fonts/NanumSquareEB.woff2') format('woff2'),
      url('../fonts/NanumSquareEB.woff') format('woff'),
      url('../fonts/NanumSquareEB.ttf') format('truetype');
}
@font-face {
 font-family: 'NanumSquare';
 font-weight: 300;
 src: url('../fonts/NanumSquareL.eot');
 src: local('나눔스퀘어 Light'),
      url('../fonts/NanumSquareL.eot?#iefix') format('embedded-opentype'),
      url('../fonts/NanumSquareL.woff2') format('woff2'),
      url('../fonts/NanumSquareL.woff') format('woff'),
      url('../fonts/NanumSquareL.ttf') format('truetype');
}

html {
  font-size: 8px;
}

body {
    margin: 0px;
}