:root {
    --title-font: UhbeeSehyun;
    --text-font: NanumSquare;
}

.module :focus {
    outline-color: #F90;
}

header {
    width: 100vw;
    background-color: #333;
}

.top-wrapper {
    display: flex;
    height: 100%;
    margin: auto;
}

span.top-title {
    display: block;
    color: #FFF;
    font-family: var(--title-font);
}

.main-wrapper {
    display: grid;
    background-color: #FFF;
    font-family: var(--text-font);
}

li {
    margin: 1.5rem;
    font-family: var(--title-font);
    font-size: 2rem;
    transition: all 0.5s;
}

nav li::before {
    content: "";
    padding-left: 2rem;
}

nav li:hover {
    color: #999;
    font-size: 2.25rem;
    transition: all 0.5s;
}

nav li.section::before {
    content: none;
}

nav li.section:hover {
    transform: none;
}

span.section {
    display: block;
    margin: 0 0 1.5rem 0;
    padding: 1rem 2rem;
    border-radius: 0 3rem 3rem 0;
    background: linear-gradient(to bottom right, #333, #999);
    color: #FFF;
    font-size: 2.5rem;
}

.module {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 18rem;
    margin: 1.5rem;
    padding: 1.5rem;
    box-shadow: 2px 2px 4px #333;
    border-radius: 1rem;
    outline-style: solid;
    outline-width: 1px;
    font-size: 2rem;
}

.item {
    display: flex;
    flex-direction: column;
}

input {
    width: 100%;
    box-sizing: border-box;
}

button {
    padding: 1rem;
    border: none;
    border-radius: 1rem;
    color: #FFF;
    font-size: 2rem;
    transition: background-color 0.3s;
}

button[type='button'] {
    background-color: #333;
}

button[type='button']:hover {
    background-color: #999;
}

button[type='submit'] {
    background-color: #06F;
}

button[type='submit']:hover {
    background-color: #9CF;
}

button:hover, button:focus {
    cursor: pointer;
    transition: background-color 0.3s;
}

.module form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.module form button {
    grid-column: 1 / 3;
}

span.notice {
    color: #F00;
    font-size: 1.25rem;
    text-align: center;
}

span.inform {
    font-size: 1.25rem;
}

span.nickname {
    font-size: 2.25rem;
    font-weight: 800;
}

h1 {
    margin: 1.5rem;
    font-family: var(--title-font);
    font-size: 6rem;
}

hr {
    width: calc(100% - 3rem);
    margin: 1.5rem;
    border-style: solid;
}

p {
    margin: 1.5rem;
    font-size: 2rem;
}

/* PC */
@media screen and (min-width: 1280px) {
    body {
        background-color: #CCC;
    }

    header {
        min-width: 1152px;
        height: 8rem;
    }
    
    .top-wrapper {
        max-width: 1152px;
    }
    
    span.top-title {
        float: left;
        margin: auto 1.5rem;
        font-size: 4rem;
    }
    
    .main-wrapper {
        grid-template-areas: "leftside main rightside";
        grid-template-columns: 2fr 8fr 2fr;
        max-width: 144rem;
        min-height: calc(100vh - 8rem);
        margin: 0 auto;
    }

    aside.left-side {
        grid-area: leftside;
    }

    main {
        grid-area: main;
    }

    aside.right-side {
        grid-area: rightside;
    }
}

/* Tablet */
@media screen and (min-width: 768px) and (max-width: 1279px) {
    header {
        height: 8rem;
    }
    
    .main-wrapper {
        grid-template-areas: "leftside main rightside";
        grid-template-columns: 24rem auto 24rem;
        min-height: calc(100vh - 8rem);
        margin: 0 auto;
    }

    span.top-title {
        margin: auto;
        font-size: 4rem;
    }
}

/* Mobile */
@media screen and (max-width: 767px) {
    header {
        height: 5rem;
    }

    aside {
        display: none;
    }

    span.top-title {
        margin: auto;
        font-size: 2.5rem;
    }
}